/* Updated CSS with display: flex */

/* Variables */
/* :root {
    --logo-max-width: 200px;
    --logo-max-height: 60px;
  }
   */
  /* .logogrid */
  .logogrid {
  
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next row */
    justify-content: space-between; /* Adjust the spacing between items */
    gap: 3.2rem; /* Adjust the gap between items */
    padding-bottom: 50px;
  }
  
  /* .logogrid__item */
  .logogrid__item {
    list-style-type: none;
    flex: 0 1 calc(15%); /* Adjust the item width and spacing */
    display: flex;
    align-items: center;
    justify-content: center;
    
  }
  
  /* .logogrid__img */
  .logogrid__img {
    
    object-fit: cover;

    max-width: 150px;
    max-height: 150px;
    display: flex;
    align-items: center;
    /* max-height: var(--logo-max-height); */
    /* filter: grayscale(100%) contrast(25%); */
    transition: filter 0.3s ease;
  }
  
  /* .logogrid__img:hover {
    filter: grayscale(0) contrast(100%);
  }
   */
  
  .clientcontainer {
    padding-top: 100px;
    width: 80%;
    /* max-width: 1000px; */
    margin: auto;
  } 
 
  @media (max-width: 768px) {
    .logogrid{
      padding: 20px;
    }
  }
  