/* General styles for the footer */
.footer {
  background-color: #004aad;
  color: white;
  padding: 40px 0px;
  text-align: left;
  position: relative;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

/* Footer content container */
.footer-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 15px;
  justify-content: space-around;
  max-width: 1200px;
  margin: 0 auto;
}

/* Footer column styles */
.footer-column {
  flex: 1;
  margin: 10px;
  min-width: 200px;
}

.footer-column h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column ul li {
  margin-bottom: 10px;
}

.footer-column ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.footer-column ul li a:hover {
  color: #ffddc1;
}

.footer-column p {
  font-size: 1rem;
  margin: 5px 0;
}

/* Rights reserved text */
.footer-rights {
 
  text-align: center;
  padding: 15px 0;
  background-color: #003377;
}

.footer-rights p {
  margin: 0;
  font-size: 1rem;
}

/* Responsive design */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    min-width: 100%;
    margin-bottom: 20px;
  }
}
