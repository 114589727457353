
@font-face {
  font-family: 'HeyMarch';
  src: url('../fonts/Hey March.ttf') format('truetype');
}

@font-face {
  font-family: 'Grunge';
  src: url('../fonts/Grunge.ttf') format('truetype');
}




 .intro {
  /* background: #f1e9c9eb; */
  background-color: #f5f5f5;
  padding: 100px 0;
  display: flex;
  flex-direction: row;
}

.hiscontainer {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.hiscontainer h2{
font-size: 32px;
margin-bottom: 20px;
color: #333;
}

.hishead{

  font-size: 2.5rem;
}
.hisinfo{
 
  padding-left: 20px;
    text-align: justify;
    margin-top: 15px;
}
.hisinfo>p{
    margin-bottom: 15px;
}


/* TIMELINE
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.timeline ul {
  background: #f5f5f5;
  /* background: #f1e9c9eb; */
  padding: 50px 0;
}

.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: #fff;
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
  z-index: 1;
}

.timeline ul li div {
    /* in this there is problem in mobile view*/
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
  background: white;
  /* background: #ecdcb7; */

}


 .timeline ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
  width: 0;
  height: 0;
  border-style: solid;
}

.timeline ul li:nth-child(odd) div {
  left: 45px;
}

.timeline ul li:nth-child(odd) div::before {
  left: -15px;
  border-width: 8px 16px 8px 0;
  border-color: transparent #fff transparent transparent;
}

.timeline ul li:nth-child(even) div {
  left: -439px;
}

.timeline ul li:nth-child(even) div::before {
  right: -15px;
  border-width: 8px 0 8px 16px;
  border-color: transparent transparent transparent #fff;
}

time {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 8px;
} 


/* EFFECTS
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* .timeline ul li::after {
  transition: background 0.5s ease-in-out;
}

.timeline ul li.in-view::after {
  background: #f45b69;
}

.timeline ul li div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.timeline ul li:nth-child(odd) div {
  transform: translate3d(200px, 0, 0);
}

.timeline ul li:nth-child(even) div {
  transform: translate3d(-200px, 0, 0);
}

.timeline ul li.in-view div {
  transform: none;
  visibility: visible;
  opacity: 1;
} */


/* GENERAL MEDIA QUERIES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

/* @media screen and (max-width: 900px) {
  .timeline ul li div {
    width: 250px;
  }
  .timeline ul li:nth-child(even) div {
    left: -289px; */
    /*250+45-6*/
  /* }
} */

@media screen and (max-width: 600px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li div {
    width: calc(100vw - 91px);
  }
  .timeline ul li:nth-child(even) div {
    left: 45px;
  }
  .timeline ul li:nth-child(even) div::before {
    left: -15px;
    border-width: 8px 16px 8px 0;
    border-color: transparent #ecdcb7 transparent transparent;
  }
}


/* EXTRA/CLIP PATH STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
 .timeline-clippy ul li::after {
  width: 40px;
  height: 40px;
  border-radius: 0;
}

.timeline-rhombus ul li::after {
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}

.timeline-rhombus ul li div::before {
  bottom: 12px;
}

.timeline-star ul li::after {
  clip-path: polygon(
    50% 0%,
    61% 35%,
    98% 35%,
    68% 57%,
    79% 91%,
    50% 70%,
    21% 91%,
    32% 57%,
    2% 35%,
    39% 35%
  );
}

.timeline-heptagon ul li::after {
  clip-path: polygon(
    50% 0%,
    90% 20%,
    100% 60%,
    75% 100%,
    25% 100%,
    0% 60%,
    10% 20%
  );
} 

 .timeline-infinite ul li::after {
  animation: scaleAnimation 2s infinite;
}

@keyframes scaleAnimation {
  0% {
    transform: translateX(-50%) scale(1);
  }
  50% {
    transform: translateX(-50%) scale(1.25);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
} 


/* FOOTER STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */
/* .page-footer {
  position: fixed;
  right: 0;
  bottom: 20px;
  display: flex;
  align-items: center;
  padding: 5px;
  color: black;
  background: rgba(255, 255, 255, 0.65);
}

.page-footer a {
  display: flex;
  margin-left: 4px;
} */


.team-info {
  padding: 50px 0;
  background-color: white;
}

.team-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.team-list li {
  margin: 20px;
  text-align: center;
}

.team-list li img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit:contain;
  margin-bottom: 10px;
}

.team-list li strong {
  display: block;
  font-size: 1.2rem;
}

.team-list li span {
  font-size: 1rem;
}


.director-message {
  padding: 50px 0;
  background-color: #f9f9f9;
}

.director-message .hiscontainer {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.director-message h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.director-message p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.historyphotos{
  width: 350px;
}





.mission-section {
  background-color: #007bff;
  background-color: #004aad;
  background-color: #f5f5f5;
  /* color: #ffffff; */
  padding: 80px 20px;
  text-align: center;
}

.mission-content {
  max-width: 800px;
  margin: 0 auto;
}

.mission-content h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

.mission-text {
  font-size: 1.2em !important;
  line-height: 1.6;
  text-align: left;
  padding-left: 100px;
  font-family: 'HeyMarch', Arial, sans-serif;
}

.highlight {
  font-weight: bold;
  color: #004aad;
  font-family: 'Grunge', Arial, sans-serif;
}




/* .director-message-section {
  padding: 50px 20px;
  background-color: #f5f5f5;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  margin: auto;
}

.director-message-content {

  margin: 0 auto;
}

.director-info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row-reverse;

  gap: 30px;
}

.director-photo {
  width: 300px;
  height: 350px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 50px;
  margin-bottom: 20px;
  border: 5px solid #e0e0e0;
  transition: transform 0.3s ease;
}

.director-text {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.director-text h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.director-text p {
  font-size: 1em;
  line-height: 1.2;
  text-align: justify;
  margin-bottom: 8px;
}

@media (max-width: 768px) {
  .director-info {
    flex-direction: column;
    align-items: center;
  }

  .director-photo {
    margin-bottom: 20px;
  }
}
 */


 .director-message-section {
  padding: 50px 20px;
  background-color: #007bff;
  background-color: #004aad;
  background-color: #f5f5f5;
  /* border-radius: 15px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* max-width: 1200px; */
  margin: auto;
}

.director-message-section h2{
  /* color: white; */
  /* color: #ff3131; */
}

.director-message-content {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
 
}

.chairman-info {
  display: flex;
 
  flex-direction: row-reverse;
    align-items: center;
  text-align: justify;
}
.director-info {
  display: flex;
 
  flex-direction: row;
    align-items: center;
  text-align: justify;
}

.director-photo {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 5px solid #e0e0e0;
  transition: transform 0.3s ease;
}

.director-photo:hover {
  transform: scale(1.05);
}

.director-text {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 60px 0px 60px 0px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.director-text h2 {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
}

.director-text p {
  font-size: 18px;
  line-height: 1.6;
  color: #666;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .chairman-info{
    flex-direction: column;
  }
  .director-message-content {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  .director-info{
    flex-direction: column;
  }
  .director-photo {
    width: 400px;
    height: 400px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .director-text {
    padding: 40px;
  }
}



.safety-section {
  padding: 40px 100px;
  background-color: #ffffff; /* White background */
  text-align: center;
  color: #2c3e50; /* Dark text color for contrast */
}


@media (max-width: 768px) {
  .director-photo {
    width: 250px;
    height: 250px;
    margin-right: 20px;
    margin-bottom: 0;
  }


  .safety-section {
    padding: 40px 40px;}

}



.safety-section h2 {
  /* font-size: 2em; */
  font-size: 32px;
margin-bottom: 20px;
color: #333;
  
  
 
}

/* .safety-section h3 {
  font-size: 1.3em;
  color: #2980b9; 
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: bold;
  text-transform: uppercase;
} */

.safety-section p {
  /* max-width: 1000px; */
  /* padding: 0px auto; */
  font-size: 1em;
  color: #34495e; /* Darker color for text */
  /* margin-bottom: 20px; */
  line-height: 1.6; /* Improved line spacing for readability */
}

.safety-section ul {
  font-size: 0.8em;
  list-style-type: disc;
  margin: 0;
  padding: 20;

  text-align: left;
  color: #34495e;
}

.safety-section ul li {
  margin-bottom: 10px;
  font-size: 1.2em;
}

.safety-section .photo-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 30px; /* Space between text and photos */
}

.safety-section .photo-gallery img {
  width: 100%;
  max-width: 300px;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  transition: transform 0.3s; /* Smooth hover effect */
}

.safety-section .photo-gallery img:hover {
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Additional styling for improved appearance */
.safety-section .safety-introduction,
.safety-section .safety-training,
.safety-section .safety-certifications {
  /* margin-bottom: 40px; */
}

.safety-section .safety-policies {
  
  /* margin-bottom: 40px; */
}

.safety-section .safety-policies ul {
  text-align: left;
  list-style: inside circle; /* Inside circle bullets for variety */
  /* padding-left: 20px;  */
}

.safety-section p {
  text-align: justify; /* Justify text for a cleaner look */
}

/* .safety-section h3 {
  border-bottom: 2px solid #2980b9; 
  display: inline-block;
  padding-bottom: 5px;
} */



/* .safety-section{
  padding: 40px 140px;
} */


/* General styles for the vision section */
.vision-section {
  /* background: linear-gradient(to right, #f0f2f5, #dfe7ec); */
  background-color: #f5f5f5;
  padding: 50px 20px;
  /* border-radius: 15px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
  /* font-family: 'Arial', sans-serif; */
}

/* Vision content container */
.vision-content {
  max-width: 800px;
  margin: 0 auto;
}

/* Vision heading */
.vision-content h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
}

/* Vision text */
.vision-text {
  font-size: 1.2rem !important;
  font-family: 'HeyMarch', Arial, sans-serif;
  line-height: 1.6;
  text-align: left; 
  padding-left: 100px;
  
}

/* Highlighted text */
/* .highlight {
  color: #d9534f;
  font-weight: bold;
  background: linear-gradient(to right, #ffddc1, #ffb3b3);
  padding: 0 5px;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
} */
/* 
.highlight:hover {
  background: linear-gradient(to right, #ffb3b3, #ffddc1);
  color: #333;
} */





.mission-content h2::after,
.vision-content h2::after {
  content: '';
  width: 60px;
  height: 4px;
  background: #007bff;
  display: block;
  margin: 10px auto 0;
}




.mission-section, .vision-section {
  display: flex;
  /* align-items: center; */
  /* margin: 50px 0; */
  padding: 60px 40px;
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.mission-section {
  padding: 120px 40px 40px 40px;
  background-color: #f0f8ff; 
  background-color: white;
}

.vision-section {
  background-color: #e6e6fa; 
  background-color: white;
}

.mission-content, .vision-content {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.mission-image, .vision-image {
  flex: 1;
  text-align: center;
}

.mission-image img, .vision-image img {
  max-width: 50%;
  /* height: 50%; */
  /* border-radius: 10px; */
  transition: transform 0.3s ease-in-out;
}

.mission-image img:hover, .vision-image img:hover {
  transform: scale(1.05);
}

.highlight {
  color: #0c56b7; /* Tomato */
  font-weight: bold;
}

.mission-text, .vision-text {
  font-size: 18px;
  /* line-height: 1.6; */
  /* color: #333; */
}



/* For mobile view */
@media (max-width: 768px) {
  .mission-section{
    flex-direction: column;
    padding: 40px 20px 20px 20px;
    
  }

  .mission-text{
    text-align: center;
    font-size: 1.2em !important;
  line-height: 1.6;
  text-align: left;
  padding-left: 0px;
  }

  .vision-text{
    text-align: center;
    font-size: 1.2em !important;
  line-height: 1.6;
  text-align: left;
  padding-left: 0px;
  }

  .vision-section{
    padding: 0px 20px 20px 20px;
    flex-direction: column-reverse;
  }

  .mission-image, .vision-image {
    margin-bottom: 20px;
  }
}
