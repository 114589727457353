.career-page {
    /* padding: 20px; */
    /* max-width: 1200px; */
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .career-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .career-header h1 {
    font-size: 2.5em;
    color: #0056b3;
  }
  
  .initiatives, .job-openings, .why-work-with-us, .growth-and-development, .application-process, .company-culture, .faq, .contact-info, .join-us, .life-at-progressive{
    padding: 60px;
    margin-bottom: 60px;
  }
  
  .initiatives h2, .job-openings h2, .why-work-with-us h2, .growth-and-development h2, .application-process h2, .company-culture h2, .faq h2, .contact-info h2, .join-us h2, .life-at-progressive h2 {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* .reasons */
  .initiative-list, .job-list, .process-steps, .faq-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  /* .reason */
  .initiative, .job, .step, .faq-item {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    flex: 1 1 calc(33.333% - 20px);
  }
  
  .initiative h3, .job h3, .reason h3, .step h3, .faq-item h3 {
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .initiative p, .job p, .reason p, .step p, .faq-item p {
    font-size: 1em;
  }
  
  .initiative svg, .job svg, .reason svg, .step svg {
    margin-bottom: 10px;
    color: #0056b3;
  }
  
  .join-form {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .join-form label {
    flex: 1 1 calc(50% - 20px);
  }
  
  .join-form label input, .join-form label select, .join-form label textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .join-form label textarea {
    height: 100px;
  }
  
  .join-form button {
    background: #0056b3;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .join-form button:hover {
    background: #003d80;
  }
  
  .growth-content, .culture-content, .contact-content {
    display: flex;
    gap: 20px;
  }
  
  .growth-content img, .culture-content img, .contact-content img {
    max-width: 50%;
    border-radius: 8px;
  }
  
  .process-steps .step {
    flex: 1 1 calc(25% - 20px);
  }
  




  
  
  
  .photo-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
  
  .photo-gallery img {
    width: 200px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .photo-gallery img:hover {
    transform: scale(1.05);
  }
  
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .modal-content {
    position: relative;
    background-color: #fff;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    max-width: 700px;
    max-height: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    animation: slideIn 0.3s ease-in-out;
  }
  
  @keyframes slideIn {
    from { transform: translateY(-50px); }
    to { transform: translateY(0); }
  }
  
  .modal-content img {
    width: 100%;

    border-radius: 10px 10px 0 0;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #000;
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .close:hover,
  .close:focus {
    color: #bbb;
    text-decoration: none;
  }
  



  .life-content {
    /* max-width: 800px; */
    margin: 0 auto;
    text-align: left;
  }
  
  .life-content h4 {
    margin-top: 1rem;
    color: #0056b3;
  }


  /* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 50vh; 
  overflow: hidden;
  margin-bottom: 50px;
}

/* Video */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Text Overlay */
.hero-text {
  width: 70vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Text color */
  z-index: 1;
  
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.8); */
  /* backdrop-filter: blur(0px);  */
}

/* Additional styling for text */
.hero-text h1 {
  text-shadow: 0px 10px 20px rgb(0, 0, 0);

  font-size: 3.4rem; /* Adjust font size */
  font-weight: 900;
  margin-bottom: 1rem;
  /* text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);  */
}

.hero-text p {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 2rem;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7); /* Text shadow for better visibility */
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.5rem; /* Adjust font size */
  }

  .hero-text p {
    font-size: 1.2rem; /* Adjust font size */
  }

  .hero-text {
    width: 90vw; /* Adjust width for smaller screens */
    padding: 1.5rem; /* Adjust padding */
  }
}



.reasons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.reason {
  text-align: center;
  width: 250px;
  padding: 20px;
  /* background-color: #f0f0f0; */
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.reason h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.reason p {
  font-size: 1rem;
  color: #666;
}

.reason svg {
  /* color: #ff6f61; */
  margin-bottom: 10px;
}



.join-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-control-file {
  width: 100%;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

select.form-control {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
  background-repeat: no-repeat;
  background-position-x: calc(100% - 10px);
  background-position-y: center;
  background-size: 12px;
  padding-right: 30px;
}

.btn-primary {
  display: inline-block;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-primary:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.btn-primary:focus {
  outline: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .join-form {
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .form-control {
    font-size: 0.9rem;
  }
}



.why-work-with-us {
  text-align: center;
  padding: 50px 20px;
  background-color: #f9f9f9;
}

.why-work-with-us h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}

.why-work-with-us h2::after {
  content: '';
  width: 80px;
  height: 3px;
  background-color: #004aad;
  display: block;
  margin: 10px auto;
}

.reasons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.reason {
  background-color: #fff;
  border-radius: 10px;
  padding: 30px;
  width: 250px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.reason:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.reason h3 {
  font-size: 24px;
  /* color: #ff8c00; */
  margin: 20px 0;
}

.reason p {
  font-size: 16px;
  color: #666;
  line-height: 1.6;
}

.reason svg {
  /* color: #ff8c00; */
}

@media (max-width: 768px) {
  .reasons {
    flex-direction: column;
    align-items: center;
  }

  .reason {
    width: 80%;
    margin-bottom: 30px;
  }
}


.join-us {
  text-align: center;
  /* padding: 50px 20px; */
  /* background-color: #f4f4f9; */
}

.join-us h2 {
  font-size: 36px;
  color: #333;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
}

.join-us h2::after {
  content: '';
  width: 80px;
  height: 3px;
  background-color: #004aad;
  display: block;
  margin: 10px auto;
}

.join-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
}

.form-group input[type="text"],
.form-group select,
.form-group input[type="file"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group select:focus,
.form-group input[type="file"]:focus {
  border-color: #007bff;
}

.form-group input[type="file"] {
  padding: 5px;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  padding: 15px 30px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.btn-primary:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .join-form {
    padding: 20px;
  }
}
