@import url("https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap");

.column {
  /* flex: 0.3; */
  float: left;
  width: 50%;
  /* text-overflow:ellipsis; */
}

.bold {
  font-weight: 80px;
  font-size: 95px;
  /* padding-top: 180px; */
  /* padding-left: 50px; */
  /* font-family: "Sedgwick Ave", cursive; */
  font-family: "Passion One", cursive;
  color: #333;
}

.bold2 {
  font-weight: bold;
  font-size: 95px;
  font-family: "Passion One", cursive;
  color: #000000a4;
}

.container2 {
  height: 100vh;
  padding: 160px 60px 80px;
}

.animation-container {
  /* padding-top: 100px; */
  /* padding-right: 50px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 80px;
}

.animation-container .visible {
  display: flex;
  align-items: center;
}

.animation-container .visible p {
  margin-left: 10px;
  font-size: 18px;
  color: #555;
}

.animation-container svg {
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  margin-right: 20px;
}

.belief {
  font-size: 18px;
  color: #555;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.visible {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.himg {
  height: 400px; /* Adjust the width as needed */
  max-width: 550px;
  width: auto; /* Ensures the aspect ratio is maintained */
}

.htext {
  margin-top: 10px; /* Adjust the margin between image and text */
  font-size: 30px !important; /* Adjust the font size */

  font-weight: bold; /* Make the text bold */
  color: #333; /* Set the text color */
}

.custom-typewriter {
  font-size: 2em; /* Adjust font size */
  font-weight: bold; /* Make text bold */
  color: #333; /* Change text color if needed */
}

.custom-cursor {
  display: inline-block; /* Ensure cursor appears inline with the text */
  animation: blink 0.7s infinite; /* Add blinking animation */
  font-size: 2em; /* Adjust cursor size */
  color: #333; /* Change cursor color if needed */
}

@keyframes blink {
  0% {
    opacity: 1; /* Cursor is visible */
  }
  50% {
    opacity: 0; /* Cursor is invisible */
  }
  100% {
    opacity: 1; /* Cursor is visible */
  }
}

.benefits {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 50px;
}

.benefit {
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.benefit h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.benefit p {
  font-size: 1rem;
  color: #666;
}

.welcome-section {
  padding: 20px 0;
  /* background-color: #f9f9f9; */
}

.welcome-content {
  /* max-width: 800px; */
  width: 80vw;
  margin: 0 auto;
  text-align: center;
}

.welcome-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.welcome-content p {
  font-size: 1.2rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

/* Home.css */

/* Dynamic content section */
.dynamic-content {
  /* padding: 200px 0; */
  background-color: #ffffff;
  text-align: center;
}

/* About Us section */
.about-section {
  padding: 50px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.about-container {
  /* max-width: 1200px; */
  padding: 20px 150px;
  margin: 0 auto;
}

.about-container h2 {
  padding-bottom: 30px;
  font-size: 36px;
  color: #333;
}

.about-container p {
  font-size: 18px;
  text-align: justify;
  color: #666;
  line-height: 35px;
}

/* Key Features section */
.features-section {
  padding: 80px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.features-container {
  max-width: 1200px;
  margin: 0 auto;
}

.features-container h2 {
  font-size: 36px;
  color: #333;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

.feature-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 48px;
  color: #333;
  margin-bottom: 20px;
}

.feature-item h3 {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}

.feature-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

/* Core Areas of Expertise section */
.expertise-section {
  padding: 80px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.expertise-container {
  max-width: 1200px;
  margin: 0 auto;
}

.expertise-container h2 {
  font-size: 36px;
  color: #333;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
}

.expertise-item {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.expertise-item h3 {
  margin-top: 20px;
  font-size: 24px;
  color: #333;
}

.expertise-item p {
  margin-top: 10px;
  font-size: 16px;
  color: #666;
}

/* Contact Us section */
.contact-section {
  padding: 80px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.contact-container {
  /* max-width: 800px; */
  margin: 0 auto;
}

.contact-container h2 {
  font-size: 36px;
  color: #333;
}

.contact-container p {
  font-size: 18px;
  color: #666;
}

/* Footer section */
.footer {
  padding: 20px 0;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.achyut {
  display: flex;
  flex-direction: row;
  /* padding: 250px 150px 100px; */
}

.achyutimagination {
  width: 300px; /* Set your desired width */
  height: auto;
  padding: 20px;
  opacity: 0; /* Initially hide images */
  transition: opacity 0.5s ease-in-out; /* Add smooth transition */
  animation: slideIn 0.5s forwards; /* Apply animation to all images */
}

/* Define animation */
.animate {
  opacity: 1; /* Ensure images are visible at the end of the animation */
}

/* Keyframes for animation */
@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateX(-50px); /* Initial position outside the viewport */
  }
  100% {
    opacity: 1;
    transform: translateX(0); /* Final position at original position */
  }
}

/* Stagger animation for each image */
.achyutimagination:nth-child(1) {
  animation-delay: 0.5s; /* Delay animation for the first image by 0.5s */
}

.achyutimagination:nth-child(2) {
  animation-delay: 1s; /* Delay animation for the second image by 1s */
}

.achyutimagination:nth-child(3) {
  animation-delay: 1.5s; /* Delay animation for the third image by 1.5s */
}

.achyutimagination:nth-child(4) {
  animation-delay: 2s; /* Delay animation for the fourth image by 2s */
}

.achyuttext {
  padding: 35px 30px;
  font-size: 20px;
}

.expertise-container {
  /* max-width: 1200px; */
  margin: 0 auto;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.expertise-item {
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.iconcol {
  flex: 0 0 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infocol {
  flex: 1;
  padding: 20px;
}

.expertise-icon {
  font-size: 85px;
}

.expertise-item h3 {
  font-size: 1.5rem;
  color: #333;
}

.expertise-item p {
  font-size: 1rem;
  color: #666;
}

.stats-awards-section {
  padding: 80px 0;
  background-color: #f9f9f9;
  text-align: center;
}

.stats-awards-container {
  /* max-width: 1200px; */
  margin: 0 auto;
}

.stats-awards-container h2 {
  font-size: 36px;
  color: #333;
}

.stats-awards-grid {
  /* display: grid; */
  width: 900px;
  padding: 50px;
  /* grid-template-columns: repeat(2, 1fr); */
  /* gap: 20px; */
}

.stats-item {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.certificate {
  height: 320px;
}

.awards {
  width: 450px;
}

@media (max-width: 768px) {
  .row {
    display: flex;
    flex-direction: column;
    /* padding: 70px 0 0; */
    margin: 0;
  }

  


  .bold {
    font-size: 40px;
  }
  .bold2 {
    font-size: 40px;
  }

  .dynamic-content {
    padding: 100px 0px;
  }

  .himg{
    width: 150%;
    object-fit: contain;
  }

  /* About us */

  .about-container{
    padding: 40px;
  }


  /*  */

  .feature-item{
    margin: 0 20px;
  }

  /* expertise container */
  .expertise-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .expertise-section{
    padding: 0;
    padding-bottom: 20px;
  }

 h2{
  padding-top: 30px;
    padding-bottom: 10px;
  }

  

  .expertise-item{
    padding: 0 10px;
    margin: 0 20px;
  }

  /* stats and award */
  .stats-awards-section {
    padding: 20px;
  }

  .awards {
    width: 100%;
  }

  .stats-awards-grid {
    /* padding: 0; */
    width: 100%;
  }
  .stats-item {
    flex-direction: column;
  }

  /* contact us */
  .contact-container {
    display: flex;
    flex-direction: column;
  }

  h2 {
    font-size: 30px;
   
  }
 

  p {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
}



/* Hero Section */
.hero-section {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust height as needed */
  overflow: hidden;
}

/* Video */
.hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* Text Overlay */
.hero-text {
  width: 70vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff; /* Text color */
  z-index: 1;
}

/* Additional styling for text */
.hero-text h1 {
  font-size: 3.4rem; /* Adjust font size */
  font-weight: 900;
  margin-bottom: 1rem;
}

.hero-text p {
  font-size: 1.5rem; /* Adjust font size */
  margin-bottom: 2rem;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .hero-text h1 {
    font-size: 2.5rem; /* Adjust font size */
  }

  .hero-text p {
    font-size: 1.2rem; /* Adjust font size */
  }
}

/* 

.achievements {
  display: flex;
  justify-content: space-around;
  padding: 40px;
  margin: 20px 0;
  flex-wrap: wrap;
}

.achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  width: 300px;
  height: 200px;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
}

.achievement:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.achievement-info {
  margin-top: 10px;
}

.achievement-info p {
  margin: 5px 0 0 0;
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
}

.achievement-info .countup {
  font-size: 2em;
  font-weight: bold;
  color: #ff6f61;
  background: #e3f2fd;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.achievement svg {
  color: #007bff;
  transition: color 0.3s ease;
}

.achievement:hover svg {
  color: #0056b3;
} */

.achievements-section {
  background-color: #f7f7f7;
  padding: 60px 20px;
  text-align: center;
}

.section-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
}

.achievements-section {
  background-color: #f7f7f7;
  background-color: white;
  padding: 60px 20px;
  text-align: center;
}

/* .section-title {
  font-size: 2.5em;
  margin-bottom: 40px;
  color: #333;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
} */

.achievements {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.achievement {
  background: white;
  border-radius: 12px;
  /* box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); */
  padding: 20px;
  max-width: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.achievement:hover {
  /* transform: translateY(-10px); */
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); */
}

.achievement-icon {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
  filter: grayscale(100%);
}

.achievement-info p {
  font-size: 1.2em;
  color: #555;
  margin: 10px 0;
}

.achievement-info .countup {
  font-size: 1.5em;
  color: #ff5722;
  font-weight: bold;
}

.countupnumber{
  color: red;
  font-size: 30px;
}

@media (max-width: 768px) {
  .achievements {
    flex-direction: column;
    align-items: center;
  }

  .achievement {
    max-width: 90%;
    margin-bottom: 20px;
  }
}


.turnkey-projects {
  padding: 60px 20px;
  /* background: #f8f9fa; */
  text-align: center;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.turnkey-projects h2 {
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}

.turnkey-projects p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #666;
}

.process-flow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.feature {
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  max-width: 200px;
  min-height: 250px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.feature h3 {
  font-size: 1em;
  margin-top: 10px;
  color: #007bff;
}

.feature p {
  font-size: 0.65em;
  margin-top: 10px;
  color: #666;
}

.feature svg {
  color: #007bff;
  margin-bottom: 10px;
  transition: color 0.3s ease;
}

.feature:hover svg {
  color: #0056b3;
}

.arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  max-width: 20px;
  flex: 0 0 100px;
}




@media only screen and (max-width: 768px) {
  .turnkey-projects {
    padding: 60px 20px;
    text-align: center;
    border-radius: 10px;
  }
  
  .turnkey-projects h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .turnkey-projects p {
    font-size: 1.2em;
    margin-bottom: 40px;
    color: #666;
  }
  
  .process-flow {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center content */
    align-items: flex-start; /* Align items to top */
  }
  
  .feature {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    flex: 1 1 calc(33.333% - 40px); /* Flex-grow, flex-shrink, flex-basis for better responsive behavior */
    min-width: 200px;
    max-width: 300px; /* Adjusted max-width for better scaling */
    min-height: 250px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .feature:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  .feature h3 {
    font-size: 1.2em; /* Slightly increased font size */
    margin-top: 10px;
    color: #007bff;
  }
  
  .feature p {
    font-size: 0.9em; /* Slightly increased font size */
    margin-top: 10px;
    color: #666;
  }
  
  .feature svg {
    color: #007bff;
    margin-bottom: 10px;
    transition: color 0.3s ease;
  }
  
  .feature:hover svg {
    color: #0056b3;
  }

  .arrow {
    display: none; 
   
  }
}













section>h2{
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
}



.heroslider{
  /* padding-top: 70px; */
}


.statsicon{
  width: 60px;

}