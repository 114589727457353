/* ProjectDetails.css */

.project-details-container {
    /* max-width: 800px; */
    
    display: flex;
    margin: 0 auto;
    padding: 20px;
    padding-top: 80px;
  }
  
  .productdetailimag {
    /* width: 100vw; */
    height:80vh;

    width: 50vw;
    /* height: auto; */
    margin-bottom: 20px;
  }
  
  .project-details-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
    padding: 40px 20px 10px 20px;
  }
  
  .project-details-container p {
    font-size: 16px;
    line-height: 1.6;
    text-align: justify;
    padding: 10px 30px;
  }
  

  .project-brief{
    padding: 10px 30px;
  }



@media only screen and (max-width: 600px) {
  .project-details-container {
    flex-direction: column; 
}

.productdetailimag {
  width: 85vw;
    /* width: 50%; */
    height: 40vh;
}

.project-details-container div:last-child {
    padding-left: 20px;
}
}


.breadcrumb {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 10px 20px 10px; /* Adjusted for alignment */
  padding: 100px 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-size: 14px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.breadcrumb a {
  text-decoration: none;
  color: #007bff;
  margin-right: 5px; /* Spacing between breadcrumb items */
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.breadcrumb span {
  color: #6c757d;
}

.breadcrumb a:last-of-type,
.breadcrumb span:last-of-type {
  /* pointer-events: none; */
  font-weight: bold; /* Highlight the current item */
}

.breadcrumb-separator {
  margin: 0 5px;
  color: #6c757d;
}

@media only screen and (max-width: 600px) {
  .breadcrumb {
    font-size: 12px;
    padding: 8px 10px;
  }
}

