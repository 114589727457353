.carousel-image {
  width: 100%;
  object-fit:fill;
}

@media (max-width: 576px) {
  .carousel-image {
    height: 300px;
  }

  .carousel-caption {
    bottom: 10px;
  
    text-align: right;
  }

  .carousel-content {
    padding: 5px 15px;
    
    font-size: 12px;
  }

  .carousel-content h3{
    font-size: 18px;
  }
  .carousel-content h5{
    font-size: 12px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .carousel-image {
    height: 400px;
  }

  .carousel-caption {
    bottom: 15px;
 
    text-align: right;
  }

  .carousel-content {
    padding: 10px 20px;
   
  }
  .carousel-content h3{
    font-size: 18px;
  }
  .carousel-content h5{
    font-size: 12px;
  }

}

@media (min-width: 768px) and (max-width: 992px) {
  .carousel-image {
    height: 500px;
  }

  .carousel-caption {
    bottom: 20px;
   
    text-align: right;
  }

  .carousel-content {
    padding: 10px 30px;
    font-size: 16px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .carousel-image {
    height: 600px;
  }

  .carousel-caption {
    bottom: 20px;

    text-align: right;
  }

  .carousel-content {
    padding: 10px 40px;
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  .carousel-image {
    height: 700px;
  }

  .carousel-caption {
    bottom: 20px;
   
    text-align: right;
  }

  .carousel-content {
    padding: 10px 50px;
    font-size: 20px;
  }
}

.carousel-caption {
  position: absolute;
  right: -8px;
}

.carousel-content {
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
