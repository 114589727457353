

  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,700&display=swap');



.projsection {
  text-align: center;
  padding: 6em 1em;   
  /* this line affect histort as direct css given to section */
  background-color: white;
  /* margin:0 auto; */
}

.header {
  width: 960px;
  margin: 1em auto;
  text-align: center;
  border-bottom: 2px solid rgba(29,29,29,0.6);
  padding: 1em;
}

.header h1 {
  margin-bottom: 0.25em;
  font-weight: 600;
  text-transform: uppercase;
}

.header a {
  text-decoration: none;
  color: #1d1d1d;
  font-weight: 700;
  transition: all 0.35s ease-in-out;
}

.header a:hover {
  color: #e99041;
}

.row {
  align-items: center;
  /* max-width: 960px; */
  width: 100%;

  padding:0 auto;
  margin: 0 auto;
 
  justify-content:center;
}

.heading {
    margin-bottom: 1em;
}

.figure {
  background: #1d1d1d;
  display: inline-block;
  margin: 10px;

  max-width: 330px;
  min-width: 230px;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.figure * {
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.section-5 .figure {
  height: 38 0px;
}

.section-5 .figure img {
  width: 110%;

  position: relative;
  left: -12px;
  opacity: 0.7;
}



.section-5 .figure figcaption {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 13%;
  right: 13%;
  border: 1px solid rgba(239,239,239,0.8);
  border-width: 0 1px;
  background-color: rgba(29,29,29,0.2);
  color: #efefef;
  /* width: 220px; */
}

.section-5 .figure h2 {
  padding: 15px 20px;
  margin: 0;
  text-tranform: uppercase;
  font-weight: 400;
  letter-spacing: -1px;
}

.section-5 .figure h2 span {
  font-weight: 800;
}

.section-5 .figure .icon,
.section-5 .figure .caption {
  overflow: hidden;
  width: 100%;
  position: absolute;
}

.section-5 .figure .icon {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  top: 0;
}

.section-5 .figure .caption {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  bottom: 0;
}

.section-5 .figure p,
.section-5 .figure .icon span {
  display: table;
  margin: 0 auto;
  padding: 0 10px;
  position: relative;
  text-align: center;
  width: auto;
  color: rgba(239,239,239,0);
  font-size: 0.8em;
}

.section-5 .figure p:before,
.section-5 .figure .icon span:before,
.section-5 .figure p:after,
.section-5 .figure .icon span:after {
  position: absolute;
  height: 1px;
  content: '';
  background: #fff;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}

.section-5 .figure .icon span:before,
.section-5 .figure .icon span:after {
  width: 1000%;
  top: 50%;
}

.section-5 .figure .icon span:before {
  left: -1000%;
}

.section-5 .figure .icon span:after {
  right: -1000%;
}

.section-5 ion-icon {
  color: #efefef;
  font-size: 60px;
}

.section-5 .figure p:before,
.section-5 .figure p:after {
  width: 150%;
  bottom: 50%;
}

.section-5 .figure p:before {
  left: -100%;
}

.section-5 .figure p:after {
  right: -100%;
}

.section-5 .figure a {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  position: absolute;
  z-index: 1;
}

.section-5 .figure:hover img,
.section-5 .figure.hover img {
 
  opacity: 0.15;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.section-5 .figure:hover p,
.section-5 .figure.hover p {
  opacity: 1;
  -webkit-transition-delay: 0.35s;
  transition-delay: 0.35s;
  color: #efefef;
}

.section-5 .figure:hover p:before,
.section-5 .figure.hover p:before {
  left: -150%;
}

.section-5 .figure:hover p:after,
.section-5 .figure.hover p:after{
  right: -150%;
}




/* Section 6 - Hover Effect 6 */

.section-6 {
  background: #1d1d1d;
  color: #efefef;

}

.section-6 .figure {
  text-align: left;
  background: #1d1d1d;
  font-size: 16px;
  max-width: 400px;
  max-height: 200px;
  min-height: 200px;
  min-width: 230px;
}

.section-6 .figure img {
  position: relative;
  max-width: 100%;
  vertical-align: top;
}

.section-6 figcaption {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  padding: 20px 30px;
}

.section-6 .figure figcaption:before,
.section-6 .figure figcaption:after {
  width: 1px;
  height: 0;
}

.section-6 .figure figcaption:before {
  right: 0;
  top: 0;
}

.section-6 .figure figcaption:after {
  left: 0;
  bottom: 0;
}

.section-6 .figure h3,
.section-6 .figure p {
  line-height: 1.5em;
}

.section-6 .figure h3 {
  margin: 0 0 5px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-6 .figure p {
  font-size: 0.8em;
  font-weight: 400;
  margin: 0 0 15px;
}

.section-6 .figure a {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.section-6 .figure:before,
.section-6 .figure:after,
.section-6 .figure figcaption:before,
.section-6 .figure figcaption:after {
  position: absolute;
  content: '';
  background-color: #efefef;
  z-index: 1;
  -webkit-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
  opacity: 0.8;
}

.section-6 .figure:before,
.section-6 .figure:after {
  height: 1px;
  width: 0%;
}

.section-6 .figure:before {
  top: 0;
  left: 0;
}

.section-6 .figure:after {
  bottom: 0;
  right: 0;
}

.section-6 .figure:hover img,
.section-6 .figure.hover img {
  opacity: 0.4;
}

.section-6 .figure:hover figcaption:before,
.section-6 .figure.hover figcaption:before,
.section-6 .figure:hover figcaption:after,
.section-6 .figure.hover figcaption:after {
  height: 100%;
}

.section-6 .figure:hover figcaption {
  opacity: 1;
}

.section-6 .figure:hover:before,
.section-6 .figure.hover:before,
.section-6 .figure:hover:after,
.section-6 .figure.hover:after {
  width: 100%;
}

.section-6 .figure:hover:before,
.section-6 .figure.hover:before,
.section-6 .figure:hover:after,
.section-6 .figure.hover:after,
.section-6 .figure:hover figcaption:before,
.section-6 .figure.hover figcaption:before,
.section-6 .figure:hover figcaption:after,
.section-6 .figure.hover figcaption:after {
  opacity: 0.1;
}

.industrialfilters {
  display: flex;
  justify-content:center;
  align-items: center;
  margin-bottom: 50px;
  margin-top: 20px;

}

.industrialfilters div {
  padding: 6px 17px;
  margin-left: 25px;
  margin-right: 25px;
  background-color: #555;
  color: #000000;
  border-radius: 35px;
  cursor: pointer;


}

.industrialfilters div:hover {
  background-color: #fff;
}

.industrialfilters div.active {
  background-color: #fff;
  color: #000000;
}


.section-6>.heading h1{
  font-size: 60px;
} 



.infrastructureFilters div {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  margin: 0 10px;
  background-color: #555;
  color: #fff;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.infrastructureFilters div:hover {
  background-color: #fff;
  color: #000;
}

.infrastructureFilters .active {
  background-color: #fff;
  color: #000;
}


.infrastructureFilters .active {
  background-color: #fff;
  color: #000;
}


@media (max-width: 576px) {

  .section-5 .figure img {
    width: 110%;
  height: 180px;
    position: relative;
    left: -12px;
    opacity: 0.7;
  }

  .industrialfilters {
    display: flex;
    justify-content:center;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 20px;
    flex-direction: column;
  }

  .section-6 .heading h1 {
    font-size: 40px;
  }

  .industrialfilters div {
    padding: 6px 17px;
    margin-left: 25px;
    margin-right: 25px;
    background-color: #555;
    color: #000000;
    border-radius: 35px;
    cursor: pointer;
    width: 200px;
    margin-bottom: 12px;
  }
}